<template>
  <page v-bind="{ loading }" icon="list-ol" :title="Naming.Inspection" :loading-text="`Loading ${Naming.Inspections}...`">

    <template #tools>
      <div class="is-flex align-items-center">
        <action-button
          @click="advanced = !advanced"
          class="is-primary is-inverted has-text-weight-bold mr is-rounded button"
          style="min-width: 7rem"
          :left-icon="advanced ? 'chevron-up' : 'chevron-down'">
          {{ advanced ? 'Hide Filters' : 'Show Filters' }}
        </action-button>
        <router-link :to="{ name: 'failed-checks-index' }" class="is-primary is-inverted has-text-weight-bold mr is-rounded button">
          Latest Failed {{ Naming.Checks }}
        </router-link>
        <router-link
          :to="{
            name: 'overdue-inspection-index'
          }"
          class="is-primary is-inverted has-text-weight-bold mr is-rounded button">
          Overdue {{ Naming.Inspections }}
        </router-link>
        <router-link
          :to="{
            name: 'upcoming-inspection-index'
          }"
          class="is-primary is-inverted has-text-weight-bold mr is-rounded button">
          Upcoming {{ Naming.Inspections }}
        </router-link>
        <dropdown-wrapper position="bottom-end">
          <action-button
            slot="trigger"
            :working="pullingReport"
            class="is-primary is-inverted has-text-weight-bold mr is-rounded"
          left-icon="download">
            Download Report
          </action-button>
          <dropdown-item
            class="mb-1 is-flex justify-center has-text-white has-background-dark is-size-7"
            v-if="inspections.meta && inspections.meta.total > 1000">
            Reports are limited to export 1000 records at a time.
          </dropdown-item>
          <dropdown-item v-if="isAdmin" @click="downloadChecklistInspectionsReportAsExcel">
            <span class="has-text-weight-bold mr">Email</span>Export {{ Naming.Checklist }} {{ Naming.Checks }} (XLSX)
          </dropdown-item>
          <dropdown-item @click="pullReport('pdf')">Portable Document Format (PDF)</dropdown-item>
          <dropdown-item @click="pullReport('xlsx')">
            <span class="has-text-weight-bold mr"
              v-show="inspections.meta && inspections.meta.total > 1000"
            >Email</span> Excel Workbook (XLSX)
          </dropdown-item>
          <dropdown-item @click="pullReport('csv')">
            <span class="has-text-weight-bold mr"
              v-show="inspections.meta && inspections.meta.total > 1000"
            >Email</span> Comma-Separated Values (CSV)
          </dropdown-item>
        </dropdown-wrapper>
      </div>
    </template>

    <template #default>

      <div class="box is-flex mb-1">
        <div class="flex-grow grid gap-1 has-5-columns">
          <div class="is-flex">
            <input
              class="is-marginless input"
              type="number"
              v-model="filters.job_number"
              @input="runFilters"
              :placeholder="`#  ${Naming.Job} No.`">
            </input>
            <action-button
              v-if="filters.job_number"
              slot="right"
              class="is-light"
              @click="clearFilter('job_number')">
              <icon icon="times"/>
            </action-button>
          </div>

          <text-input
            class="is-marginless"
            v-model="filters.search"
            @input="runFilters"
            left-icon="barcode"
            :placeholder="`${Naming.Asset} Code / Barcode`"
            :label="false">
            <action-button
              v-if="filters.search"
              slot="right"
              class="is-light"
              @click="clearFilter('search')">
              <icon icon="times"/>
            </action-button>
          </text-input>

          <server-data-selector
            searchable
            class="is-marginless"
            :value="filters.technician_id"
            :items="userList"
            v-model="filters.technician_id"
            left-icon="user"
            value-key="id"
            label-key="full_name"
            :prompt-label="`Filter by ${Naming.Technician}`"
            @input="runFilters"
            :on-type="searchTechnician"
            :on-lose-focus="clearTechnicianFilter">
            <data-selector-user-slot
                slot="item"
                slot-scope="{ item }"
                :user="item"/>
            <action-button
                v-if="filters.technician_id"
                slot="right"
                @click="clearTechnicianFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>

          <server-data-selector
            searchable
            class="is-marginless"
            left-icon="check"
            v-model="filters.checklist"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Checklist}`"
            :items="checklistList"
            @input="checklistFilters"
            :on-type="searchChecklist"
            :on-lose-focus="clearChecklistFilter">
            <action-button
              v-if="filters.checklist"
              slot="right"
              @click="clearChecklistFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>

          <data-selector
            class="is-marginless"
            v-model="filters.status"
            @input="runFilters"
            left-icon="circle-notch"
            :items="statuses"
            prompt-label="Filter by Outcome / Status"
            :label="false">
            <action-button
              v-if="filters.status"
              slot="right"
              class="is-light"
              @click="clearFilter('status')">
              <icon icon="times"/>
            </action-button>
          </data-selector>

          <div v-show="advanced">
            <date-picker
              class="is-marginless"
              placeholder="Filter by Date (Range)"
              mode="range"
              left-icon="calendar"
              :label="false"
              ref="startDate"
              :value="filters.start_date"
              @datepicker-closed="runFilterDate"
              @input="filterStartDate">
              <action-button
                v-if="filters.start_date"
                slot="right"
                @click="clearFilter('start_date')">
                <icon icon="times"/>
              </action-button>
            </date-picker>
          </div>
          <div v-show="advanced">
            <server-data-selector
              searchable
              class="is-marginless"
              v-model="filters.asset_type_id"
              @input="runFilters"
              left-icon="building"
              value-key="id"
              label-key="name"
              :prompt-label="`Filter by ${Naming.AssetType}`"
              :items="assetTypeList"
              :on-type="searchAssetTypes"
              :on-lose-focus="clearAssetTypeFilter">
              <data-selector-site-slot
                slot="item"
                slot-scope="{ item }"
                :site="item"/>
              <action-button
                v-if="filters.asset_type_id"
                slot="right"
                @click="clearAssetTypeFilter()">
                <icon icon="times"/>
              </action-button>
            </server-data-selector>
          </div>
          <div v-show="advanced">
            <server-data-selector
                searchable
                class="is-marginless"
                v-model="filters.site_id"
                @input="runFilters"
                left-icon="building"
                value-key="id"
                label-key="name"
                :prompt-label="`Filter by ${Naming.Site}`"
                :items="siteList"
                :on-type="searchSite"
                :on-lose-focus="clearSiteFilter">
              <data-selector-site-slot
                  slot="item"
                  slot-scope="{ item }"
                  :site="item"/>
              <action-button
                  v-if="filters.site_id"
                  slot="right"
                  @click="clearSiteFilter()">
                <icon icon="times"/>
              </action-button>
            </server-data-selector>
          </div>
          <div v-show="advanced && filters.site_id">
            <data-selector
                searchable
                class="is-marginless"
                v-model="filters.zone_id"
                @input="runFilters"
                left-icon="building"
                value-key="id"
                label-key="name"
                :prompt-label="`Filter by ${Naming.Zone}`"
                :items="zoneList">
              <data-selector-site-slot
                  slot="item"
                  slot-scope="{ item }"
                  :site="item"/>
              <action-button
                  v-if="filters.zone_id"
                  slot="right"
                  @click="clearZoneFilter()">
                <icon icon="times"/>
              </action-button>
            </data-selector>
          </div>
          <div v-show="advanced">
            <server-data-selector
              class="is-marginless"
              searchable
              v-model="filters.client_id"
              @input="runFilters"
              left-icon="address-book"
              label-key="legal_name"
              value-key="id"
              :items="clientList"
              :prompt-label="`Filter by ${Naming.Client}`"
              :on-type="searchClient"
              :on-lose-focus="clearClientFilter">
              <action-button
                v-if="filters.client_id"
                slot="right"
                class="is-light"
                @click="clearClientFilter()">
                <icon icon="times"/>
              </action-button>
            </server-data-selector>
          </div>
          <div v-show="isAdmin && filters.checklist">
            <server-data-selector
              class="is-marginless"
              searchable
              v-model="filters.check"
              @input="runFilters"
              left-icon="check"
              label-key="name"
              value-key="id"
              :items="filteredChecklistChecks"
              :prompt-label="`Filter by '${Naming.Check}`"
              :on-type="searchCheck"
              :on-lose-focus="clearCheckFilter">
              <action-button
                v-if="filters.check"
                slot="right"
                class="is-light"
                @click="clearCheckFilter()">
                <icon icon="times"/>
              </action-button>
            </server-data-selector>
          </div>
          <div  v-show="isAdmin && filters.check">
            <data-selector
              class="is-marginless"
              v-model="filters.answer"
              @input="runFilters"
              left-icon="circle-notch"
              :items="answerStatuses"
              prompt-label="Filter by Answer Status"
              :label="false">
            <action-button
              v-if="filters.answer"
              slot="right"
              class="is-light"
              @click="clearFilter('answer')">
              <icon icon="times"/>
            </action-button>
          </data-selector>
          </div>
        </div>
      </div>

      <div class="box">
        <pager jump-controls :pageable="inspections" @nav="goToPage"/>
        <table class="table is-fullwidth is-vcentered is-hoverable">
          <thead>
          <tr>
            <th>{{ Naming.Job }} / {{ Naming.Inspection }} / {{ Naming.Technician }}</th>
            <th>{{ Naming.Asset}} &amp; {{ Naming.Checklist }}</th>
            <th class="is-narrow has-text-centered">{{ Naming.Checks}} Answered</th>
            <th class="is-narrow has-text-centered">Location</th>
            <th>Outcome</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="inspection in inspections.data" :key="inspection.id">
            <td>
              <div class="is-flex flex-column">
                <div>
                  <route name="inspection-evidence"
                    :params="{ asset: inspection.asset.id, inspection: inspection.id }">#{{
                      inspection.id
                    }}
                  </route>
                  <span class="has-text-grey mlr">on</span>
                  <route name="job-handler"
                    :params="{ job: inspection.job.id, site: inspection.job.site_id }">{{ Naming.Job }} {{
                      inspection.job.number
                    }} – {{ inspection.job.type.description }}
                  </route>
                </div>
                <span class="has-text-weight-light is-flex align-center mt">
                  <span class="is-inline-flex align-center">
                    <avatar :size="22" :path="inspection.technician.picture_url"
                      :name="inspection.technician.full_name"/>
                    <span class="ml">{{ inspection.technician.full_name }}</span>
                  </span>
                  <span>, {{ inspection.created_at | date('L LTS') }}</span>
                </span>
              </div>
            </td>
            <td>
              <div class="is-flex flex-column">
                <span><span class="has-text-weight-light has-text-info">Barcode:</span> {{
                    inspection.asset.barcode ? inspection.asset.barcode.data : '—'
                  }}</span>
                <span><span class="has-text-weight-light has-text-info">Internal ID:</span> {{
                    inspection.asset.code
                  }}</span>
                <span><span class="has-text-weight-light has-text-info">{{ Naming.Checklist }}:</span> {{
                    inspection.checklist? inspection.checklist.name : '-'
                  }}</span>
              </div>
            </td>
            <td class="has-text-centered">
              <span v-if="inspection.checklist && !filters.check">
                {{ inspection.answers_count }}/{{ inspection.checklist.checks_count }}
              </span>
              <span v-else>
                - <span v-if="filters.check">N/A</span>
              </span>
            </td>
            <td class="has-text-centered">
              <icon
                class="has-tooltip-left"
                :data-tooltip="hasLocation(inspection.location) ? 'Has Location Data' : 'No Location Data'"
                :class="hasLocation(inspection.location) ? 'has-text-success' : 'has-text-danger'"
                :icon="hasLocation(inspection.location) ? 'map-marker lg' : 'times-circle lg'"/>
            </td>
            <td>
              <yes-no
                v-if="inspection.passed_at || inspection.failed_at"
                :value="Boolean(inspection.passed_at)"
                yes-text="Passed"
                no-text="Failed"/>
              <inspection-status :inspection="inspection"/>
              <p class="inspection-last-active" v-if="!inspection.completed_at">
                Last active {{ inspection.updated_at | date('L LTS') }}
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <pager jump-controls :pageable="inspections" @nav="goToPage"/>
      </div>
    </template>
  </page>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import download from 'downloadjs'
import { inspection as backend } from '@/api'
import Search from '@/utils/search'

export default {

  data: () => ({

    loading: true,
    going_to_page: false,
    advanced: true,
    pullingReport: false,
    zoneList: [],
    filters: {
      search: '',
      job_number: null,
      technician_id: null,
      status: null,
      checklist: null,
      start_date: '',
      site_id: '',
      page: '',
      asset_type_id: '',
      zone_id: '',
      client_id: '',
      check: null,
      answer: null
    },

    statuses: [
      { label: 'Passed', value: 'passed' },
      { label: 'Failed', value: 'failed' },
      { label: 'Awaiting Sync', value: 'not_synced' },
      { label: 'In Progress', value: 'in_progress' },
    ],

    answerStatuses: [
      { label: 'Passed', value: '1' },
      { label: 'Failed', value: '0' },
      { label: 'N/A', value: '-1' },
    ],
  }),

  async created() {
    this.clearAllStores()
    this.filters = { ...this.$route.query }

    try {
      if (this.filters.technician_id) {
        await this.$store.dispatch('user/searchTechnician', { id: parseInt(this.filters.technician_id) })
      } else {
        await Search.searchByUri('', 'user/searchTechnician', this.$store, 0)
      }

      if (this.filters.checklist) {
        await this.$store.dispatch('checklist/searchChecklistSelectList', { id: parseInt(this.filters.checklist) })
      } else {
        await Search.searchByUri('', 'checklist/searchChecklistSelectList', this.$store, 0)
      }

      if (this.filters.client_id) {
        await this.$store.dispatch('client/searchClient', { id: parseInt(this.filters.client_id) })
      } else {
        await Search.searchByUri('', 'client/searchClient', this.$store, 0)
      }

      if (this.filters.site_id) {
        await this.$store.dispatch('site/searchSite', { id: parseInt(this.filters.site_id) })
      } else {
        await Search.searchByUri('', 'site/searchSite', this.$store, 0)
      }

      if (this.filters.asset_type_id) {
        await this.$store.dispatch('assetType/searchAssetTypes', { id: parseInt(this.filters.asset_type_id) })
      } else {
        await Search.searchByUri('', 'assetType/searchAssetTypes', this.$store, 0)
      }
      if (this.filters.site_id && this.filters.zone_id) {
        await this.$store.dispatch('zone/loadZone', parseInt(this.filters.zone_id))
        this.zoneList.push(this.$store.state.zone.zone)
      } 

      if (this.filters.checklist && this.filters.check) {
        await this.$store.dispatch('check/searchChecksList', { id: parseInt(this.filters.check) })
      } 
      

      this.loadInspections();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('checklist', [
      'setChecklistsList',
    ]),
    ...mapMutations('client', [
      'setClientList',
    ]),
    ...mapMutations('site', [
      'setSiteList',
    ]),
    ...mapMutations('assetType', [
      'setAssetTypeList',
    ]),

    getChecksFromChecklist(checklistId) {
       this.$store.dispatch('check/getChecksFromChecklist', checklistId)
    },
      
    loadInspections() {
      this.going_to_page = true
      this.$store.dispatch('inspection/loadInspections', {
        path: 'api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
        setTimeout(() => this.going_to_page = false, 100)
      })
    },
    goToPage(path) {
      this.going_to_page = true
      this.$router.push(path)
    },
    hasLocation(location) {
      return location !== null && Object.keys(location).length > 0
    },
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    addZones(siteId) {
      const site = this.siteList.find(item => item.id === siteId)
      if (site && site.zones) {
        this.zoneList = site.zones
      }
    },
    checklistFilters() {
      if(this.filters.checklist) {
        this.getChecksFromChecklist(this.filters.checklist)
      return this.runFilters()
      }
    },
    runFilters: debounce(function () {
      if (this.loading || this.going_to_page) return
      this.$router.push({
        name: 'inspection-index',
        query: this.filters
      })
    }, 500),
    filterStartDate(range) {
      this.filters.start_date = range
      if (!range) return
      this.$refs.startDate.picker.redraw()
    },
    runFilterDate(){
      if(this.filters.start_date){
        this.runFilters()
      }
    },
    searchTechnician(text) {
      Search.debouncedSearchByUri(text, 'user/searchTechnician', this.$store)
    },
    searchChecklist(text) {
      Search.debouncedSearchByUri(text, 'checklist/searchChecklistSelectList', this.$store)
    },
    searchClient(text) {
      Search.debouncedSearchByUri(text, 'client/searchClient', this.$store)
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    searchAssetTypes(text) {
      Search.debouncedSearchByUri(text, 'assetType/searchAssetTypes', this.$store)
    },
    searchCheck(text) {
      Search.debouncedSearchByUri(text, 'check/searchChecksList', this.$store)
    },
    clearTechnicianFilter() {
      this.clearFilter('technician_id') 
      this.setUserList([])
    },
    clearChecklistFilter() {
      this.clearFilter('checklist')
      this.clearFilter('check')
      this.clearFilter('answer')
      this.setChecklistsList([])
    },
    clearClientFilter() {
      this.clearFilter('client_id') 
      this.setClientList([])
    },
    clearSiteFilter() {
      this.clearFilter('site_id')
      this.clearFilter('zone_id')
      this.setSiteList([])
    },
    clearZoneFilter() {
      this.clearFilter('zone_id')
    },
    clearAssetTypeFilter() {
      this.clearFilter('asset_type_id')
      this.setAssetTypeList([])
    },
    clearCheckFilter() {
      this.clearFilter('check')
      this.clearFilter('answer')
    },
    clearAnswerFilter() {
      this.clearFilter('answer')
    },
    async pullReport(type) {
      this.pullingReport = true

      if (type === 'pdf') {
        backend.downloadFilteredIndexReport(
          this.filters,
          response => this.downloadReport(response, 'pdf', 'application/pdf'),
          error => this.$whoops()
        ).finally(() => this.pullingReport = false)
      } else if (type === 'xlsx') {
        await this.pullXlsxReport()
        this.pullingReport = false
      } else if (type === 'csv') {
        await this.pullCsvReport()
        this.pullingReport = false
      }
    },

    async downloadChecklistInspectionsReportAsExcel() {
      this.pullingReport = true
      try {
        await backend.downloadChecklistInspectionsReportAsExcel(
          this.filters,
          response => this.$toast.success(`You will receive an email at <b>${this.user.email}</b> in a few minutes with your export.`),
          error => this.$whoops()
        )
      } catch (error) {
        this.$whoops()
      } finally {
        this.pullingReport = false
      }
    },

    async pullXlsxReport() {
      if (this.inspections.meta.total <= 1000) {
        return await backend.downloadFilteredIndexReportAsExcel(
          this.filters,
          response => this.downloadReport(response, 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
          () => this.$whoops()
        )
      }

      if (!await this.$confirm({
        title: `Export as Excel Workbook`,
        message: `You are about to export <b>${this.inspections.meta.total}</b> records. This may take several minutes to construct and will be <b>emailed</b> to <b>${this.user.email}</b>. Consider additional filters if you would like a direct download. Do you want to proceed?`
      })) {
        return
      }

      await backend.emailFilteredIndexReportAsExcel(
        this.filters,
        () => this.$toast.success(`You will receive an email at <b>${this.user.email}</b> in a few minutes with your export.`),
        () => this.$whoops()
      )
    },

    async pullCsvReport() {
      if (this.inspections.meta.total <= 1000) {
        return await backend.downloadFilteredIndexReportAsCsv(
          this.filters,
          response => this.downloadReport(response, 'csv', 'text/csv'),
          () => this.$whoops()
        )
      }

      if (!await this.$confirm({
        title: `Export as Comma-Separated Values`,
        message: `You are about to export <b>${this.inspections.meta.total}</b> records. This may take several minutes to construct and will be <b>emailed</b> to <b>${this.user.email}</b>. Do you want to proceed?`
      })) {
        return
      }

      await backend.emailFilteredIndexReportAsCsv(
        this.filters,
        () => this.$toast.success(`You will receive an email at <b>${this.user.email}</b> in a few minutes with your export.`),
        () => this.$whoops()
      )
    },

    downloadReport(response, fileExtension, responseContentType) {
      this.$toast.success(`Report retrieved. Please save the ${fileExtension.toUpperCase()}.`)

      download(
        response.data,
        `All ${this.Convert('Inspections')} as at ${moment().format('DD-MM-YYYY')}.${fileExtension}`,
        responseContentType
      )
    },
    clearAllStores() {
      this.setUserList([])
      this.setChecklistsList([])
      this.setClientList([])
      this.setSiteList([])
      this.setAssetTypeList([])
    }
  },

  computed: {
    ...mapGetters('inspection', [
      'inspections'
    ]),
    ...mapGetters('user', [
      'userList',
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ]),
    ...mapGetters('client', [
      'clientList'
    ]),
    filteredChecklistChecks() {
      return this.$store.state.check.checklistChecks
    },

  },

  watch: {
    '$route': 'loadInspections',
    'filters.site_id': function (value) {
      this.addZones(value)
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearAllStores()
    next()
  }

}
</script>
