<template>
  <page v-bind="{ loading }" icon="coins" :title="Naming.AssetDashboard">

    <template #default>
      <div class="grid gap-1">
        <div class="grid gap-1 has-4-columns">
          <!-- All Assets -->
          <route class="box is-flex flex-column align-center" name="asset-admin-index">
            <span class="has-text-weight-bold is-size-2 is-paddingless">{{ assetDashboardCounts.totalAssets }}</span>
            <div>
              <span class="has-text-grey">All {{ Naming.Assets }}</span>
              <icon icon="arrow-right sm" class="is-small ml has-text-primary"/>
            </div>
          </route>

          <!-- Onboarded this Month -->
          <article class="box is-flex flex-column align-center">
            <span class="has-text-weight-bold is-size-2 is-paddingless">
              {{ assetDashboardGraphs.onboardedThisMonth }}
              <span :class="{
                'has-text-danger': assetChangePercentage < 0,
                'has-text-success': assetChangePercentage > 0
                }"
                class="is-size-6 has-text-info is-inline-flex align-center">
                <icon v-if="assetChangePercentage !== 0" :icon="(assetChangePercentage > 0) ? 'arrow-up' : 'arrow-down'"/>
                {{ assetChangePercentage.toFixed(2) + '%' }}
              </span>
            </span>
            <div>
              <span class="has-text-grey">Onboarded this month</span>
            </div>
          </article>

          <!-- Overdue for Inspection -->
          <route class="box is-flex flex-column align-center" name="asset-admin-index" :query="{ overdue: 1 }">
            <span class="has-text-weight-bold is-size-2 is-paddingless">{{ assetDashboardGraphs.overdueAssets }}</span>
            <div>
              <span class="has-text-grey">Overdue for {{ Naming.Inspection.toLowerCase() }}</span>
              <icon icon="arrow-right sm" class="is-small ml has-text-primary"/>
            </div>
          </route>

          <!-- Defective -->
          <route class="box is-flex flex-column align-center" name="asset-admin-index" :query="{ defective: 1 }">
            <span class="has-text-weight-bold is-size-2 is-paddingless">{{ assetDashboardCounts.defectiveAssets }}</span>
            <div>
              <span class="has-text-grey">Defective</span>
              <icon icon="arrow-right sm" class="is-small ml has-text-primary"/>
            </div>
          </route>
        </div>

        <div class="grid gap-1 has-3-columns">
          <!-- Overall Health (Chart) -->
          <widget title="Overall Health">
            <asset-health
              v-if="parsedAssetHealth"
              :data="parsedAssetHealth"/>
            <no-data v-else
              title="No Data"
              :description="`You don’t have any ${Naming.Assets.toLowerCase()}. Once the first one is onboarded, we’ll start showing you health-related data.`"/>
          </widget>

<!--           Onboarded this Month (Chart)-->
          <widget title="Onboarded this Month" class="is-2-columns">
            <assets-over-time
              v-if="assetDashboardGraphs.onboardingGraph"
              :data="assetDashboardGraphs.onboardingGraph"/>
            <no-data v-else
              title="Nothing Onboarded"
              :description="`No new ${Naming.Assets.toLowerCase()} were onboarded this month.`"/>
          </widget>
        </div>

        <!-- Latest Onboarded -->
        <widget collapseable title="Latest Onboarded" class="is-slot-paddingless">
          <table class="table is-fullwidth" v-if="assetDashboardLists.latestOnboarded.length">
            <thead>
              <tr>
                <th>Code</th>
                <th>{{ Naming.Site }} and {{ Naming.Zone }}</th>
                <th>Date</th>
                <th>{{ Naming.Technician }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="asset in assetDashboardLists.latestOnboarded" :key="asset.id">
                <td><route class="has-text-link" name="asset-overview" :params="{ asset: asset.id }">{{ asset.code }}</route></td>
                <td>{{ asset.siteName }}<span class="has-text-grey">, {{ asset.zoneName }}</span></td>
                <td>{{ asset.createdAt | date('L') }}</td>
                <td>{{ asset.inspectionTechnician }}</td>
              </tr>
            </tbody>
          </table>
          <no-data v-else
            :title="`No ${Naming.Assets}`"
            :description="`You don’t have any ${Naming.Assets.toLowerCase()} yet. When the first one is onboarded, it’ll appear here.`"/>
        </widget>

        <!-- Recently Failed -->
        <widget collapseable title="Recently Failed (last 30 days)" class="is-slot-paddingless">
          <table class="table is-fullwidth" v-if="assetDashboardLists.latestFailed.length">
            <thead>
              <tr>
                <th>Code</th>
                <th>{{ Naming.Site }} and {{ Naming.Zone }}</th>
                <th>Failed (at time of assessment)</th>
                <th>{{ Naming.Technician }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="asset in assetDashboardLists.latestFailed" :key="asset.id">
                <td>{{ asset.code }}</td>
                <td>{{ asset.siteName }}<span class="has-text-grey">, {{ asset.zoneName }}</span></td>
                <td>
                  <span>{{ asset.lastFailedAt | date('L LTS') }}</span>
                  <span v-if="asset.latestPassedAt" class="has-text-success"> (later passed, {{ asset.latestPassedAt | date('L LTS') }})</span>
                </td>
                <td>{{ asset.inspectionTechnician }}</td>
              </tr>
            </tbody>
          </table>
          <no-data v-else
            icon="smile"
            title="No Recent Failures"
            :description="`None of your ${Naming.Assets.toLowerCase()} have moved into a state of failure recently.`"/>
        </widget>

        <!-- Latest Inspections -->
        <widget v-if="!loadingInspections" collapseable :title="`Latest ${Naming.Inspections}`" class="is-slot-paddingless">
          <table class="table is-fullwidth" v-if="assetDashboardInspections.length">
            <thead>
              <tr>
                <th>ID</th>
                <th>{{ Naming.Asset }}</th>
                <th>{{ Naming.Job }} &amp; {{ Naming.Checklist }}</th>
                <th>Date &amp; {{ Naming.Technician }}</th>
                <th>Outcome</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="inspection in assetDashboardInspections" :key="inspection.inspectionId">
                <td><route class="has-text-link" name="inspection-evidence" :params="{ asset: inspection.assetId, inspection: inspection.inspectionId }">{{ inspection.inspectionId }}</route></td>
                <td><route class="has-text-link" name="asset-overview" :params="{ asset: inspection.assetId }">{{ inspection.assetCode }}</route></td>
                <td>
                  <route class="has-text-link" name="job-handler" :params="{ job: inspection.jobId }">
                    <p>{{ inspection.jobNumber }} – {{ inspection.jobTypeDescription }}</p>
                    <p class="has-text-grey">Checklist: {{ inspection.checklistName ? inspection.checklistName : '-' }}</p>
                  </route>
                </td>
                <td>
                  <p v-if="inspection.completedAt" >{{ inspection.completedAt | date('L LTS') }}</p>
                  <p v-else>-</p>
                  <p class="has-text-grey">{{ inspection.inspectionTechnician }}</p>
                </td>
                <td>
                  <yes-no
                    v-if="inspection.passedAt || inspection.failedAt"
                    :value="Boolean(inspection.passedAt)"
                    yes-text="Passed"
                    no-text="Failed"/>
                  <yes-no v-else :value="false" no-text="Awaiting Sync" no-icon="clock" no-class="has-text-info"/>
                </td>
              </tr>
            </tbody>
          </table>
          <no-data v-else
            :title="`No ${Naming.Inspections}`"
            :description="`You don’t have any ${Naming.Inspections.toLowerCase()} yet. When the first one is performed, it’ll appear here.`"/>
        </widget>

      </div>
    </template>
  </page>
</template>

<script>
import AssetsOverTime from '@/components/graph/AssetsOverTime'
import AssetHealth from '@/components/graph/AssetHealth'
import {mapGetters} from "vuex";

export default {

  components: {
    AssetsOverTime,
    AssetHealth
  },

  data: () => ({
    loading: true,
    loadingInspections: true,
  }),

  async mounted() {
    await this.loadAssetDashboardCountsAndGraphs()
    await this.loadAssetDashboardInspections()
  },

  methods: {
    async loadAssetDashboardCountsAndGraphs(){
      Promise.all([
        this.$store.dispatch('dashboard/loadAssetDashboardCounts'),
        this.$store.dispatch('dashboard/loadAssetDashboardGraphs'),
      ]).then(() => {
        this.loading = false
      }).catch(() => {
        this.$whoops()
        this.loading = false
      })
    },
    async loadAssetDashboardInspections(){
      await this.$store.dispatch('dashboard/loadAssetDashboardLists')
      await this.$store.dispatch('dashboard/loadAssetDashboardInspections')
        .then(() => this.loadingInspections = false)
        .catch(() => this.$whoops())
    },
  },

  computed: {
    assetChangePercentage() {
      const thisMonth = this.assetDashboardGraphs.onboardedThisMonth
      const lastMonth = this.assetDashboardGraphs.onboardedLastMonth
      if (thisMonth > 0 && lastMonth === 0) return 100
      return (thisMonth > 0 && lastMonth > 0)
        ? (((thisMonth - lastMonth) / lastMonth) * 100)
        : 0
    },
    ...mapGetters('dashboard', [
        'assetDashboardCounts',
        'assetDashboardLists',
        'assetDashboardGraphs',
        'assetDashboardInspections'
    ]),
    parsedAssetHealth(){
      return {
        labels: Object.keys(this.assetDashboardCounts.assetHealth),
        series: Object.values(this.assetDashboardCounts.assetHealth)
      }
    }
  }

}
</script>
