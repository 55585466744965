<template>
  <page v-bind="{ isLoading }" :icon="'th-list'" :title="`${Naming.Assets} Import Tool`">
    <div class="columns">
      <div class="column">
        <div class="box" v-if="successfulImports.length">
          <h4 class="is-size-4">Successful Imports ({{ successfulImports.length}})</h4>
          <table class="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th width="40"></th>
              <th v-for="key in Object.keys(successfulImports[0].given_data)" :key="key" v-text="key"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importItem in successfulImports" :key="importItem.id">
              <td class="has-text-success">
                <icon icon="check-circle"></icon>
              </td>
              <td v-for="(value, key) in importItem.given_data"
                :key="key"
                v-text="value"
              ></td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="box" v-if="failedImports.length">
          <h4 class="is-size-4">Failed Imports ({{ failedImports.length }})</h4>
          <table class="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th v-for="key in Object.keys(failedImports[0].given_data)" :key="key" v-text="key"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importItem in failedImports" :key="importItem.id">
              <td v-for="(value, key) in importItem.given_data"
                :key="key"
                :class="{'tooltip has-background-danger has-text-white': importItem.failed_reason.column === key}"
                :data-tooltip="importItem.failed_reason.column === key ? importItem.failed_reason.reason : false"
                v-text="value"
              ></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import { filter } from 'lodash'

export default {
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    goToPage(path) {
      this.$router.push(path)
    },

    isPast(date) {
      return moment(date) < moment()
    },
  },

  computed: {
    ...mapGetters('imports', [
      'import',
      'importItems',
    ]),
    successfulImports() {
      return filter(this.importItems, item => item.successful)
    },
    failedImports() {
      return filter(this.importItems, item => !item.successful)
    },
  },

  created() {
    this.isLoading = true
    this.$store.dispatch('imports/loadImport', this.$route.params.import).then(() => {
      this.isLoading = false
      if (this.importItems.length === 0) {
        this.$router.push({
          name: `dashboard`
        })
      }
    })
  },
}
</script>
