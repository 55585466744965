<template>
  <page :loading="loading" icon="list-ol" :title="`Upcoming ${Naming.Inspections}`">

    <template #tools>
      <div class="is-flex align-items-center">
        <action-button
          @click="advanced = !advanced"
          class="is-primary is-inverted"
          style="min-width: 7rem"
          :left-icon="advanced ? 'chevron-up' : 'chevron-down'">
          {{ advanced ? 'Hide Advanced Filters' : 'Show Advanced Filters' }}
        </action-button>
        <action-button
          :working="downloading"
          @click="pullXlsxReport"
          class="is-primary is-outlined has-text-weight-bold is-rounded">
          Export to XLS
        </action-button>
      </div>
    </template>

    <template #default>

      <div class="box is-flex mb-1">
        <div class="flex-grow grid gap-1 has-5-columns">
          <!-- <data-selector
            class="is-marginless"
            no-revert
            v-model="filters.mode"
            @input="runFilters"
            left-icon="address-book"
            label-key="label"
            value-key="value"
            :items="modes"
            prompt-label="Mode">
            <action-button
              v-if="filters.client"
              slot="right"
              class="is-light"
              @click="clearFilter('mode')">
              <icon icon="times"/>
            </action-button>
          </data-selector> -->
          <text-input
            class="is-marginless"
            v-model="filters.search"
            @input="runFilters"
            left-icon="barcode"
            :placeholder="`${Naming.Asset} Code / Barcode`"
            :label="false">
            <action-button
              v-if="filters.search"
              slot="right"
              class="is-light"
              @click="clearFilter('search')">
              <icon icon="times"/>
            </action-button>
          </text-input>

          <data-selector
            searchable
            class="is-marginless"
            left-icon="check"
            v-model="filters.checklist"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Checklist}`"
            :items="checklistList"
            @input="runFilters">
            <action-button
              v-if="filters.checklist"
              slot="right"
              @click="clearFilter('checklist')">
              <icon icon="times"/>
            </action-button>
          </data-selector>

          <div v-show="advanced">
            <date-picker
              class="is-marginless"
              placeholder="Filter by Date (Range)"
              mode="range"
              left-icon="calendar"
              :label="false"
              ref="startDate"
              :value="filters.date"
              @input="filterStartDate">
              <action-button
                v-if="filters.date"
                slot="right"
                @click="clearFilter('date')">
                <icon icon="times"/>
              </action-button>
            </date-picker>
          </div>
          <div v-show="advanced">
            <data-selector
              searchable
              class="is-marginless"
              v-model="filters.asset_type"
              @input="runFilters"
              left-icon="building"
              value-key="id"
              label-key="name"
              :prompt-label="`Filter by ${Naming.AssetType}`"
              :items="assetTypeList">
              <data-selector-site-slot
                slot="item"
                slot-scope="{ item }"
                :site="item"/>
              <action-button
                v-if="filters.asset_type"
                slot="right"
                @click="clearFilter('asset_type')">
                <icon icon="times"/>
              </action-button>
            </data-selector>
          </div>
          <div v-show="advanced">
            <data-selector
                searchable
                class="is-marginless"
                v-model="filters.site"
                @input="runFilters"
                left-icon="building"
                value-key="id"
                label-key="name"
                :prompt-label="`Filter by ${Naming.Site}`"
                :items="siteList">
              <data-selector-site-slot
                  slot="item"
                  slot-scope="{ item }"
                  :site="item"/>
              <action-button
                  v-if="filters.site"
                  slot="right"
                  @click="clearFilter('site')">
                <icon icon="times"/>
              </action-button>
            </data-selector>
          </div>
          <div v-show="advanced && filters.site">
            <data-selector
                searchable
                class="is-marginless"
                v-model="filters.zone"
                @input="runFilters"
                left-icon="building"
                value-key="id"
                label-key="name"
                :prompt-label="`Filter by ${Naming.Zone}`"
                :items="zoneList">
              <data-selector-site-slot
                  slot="item"
                  slot-scope="{ item }"
                  :site="item"/>
              <action-button
                  v-if="filters.zone"
                  slot="right"
                  @click="clearFilter('zone')">
                <icon icon="times"/>
              </action-button>
            </data-selector>
          </div>
          <div v-show="advanced">
            <data-selector
              class="is-marginless"
              searchable
              v-model="filters.client"
              @input="runFilters"
              left-icon="address-book"
              label-key="legal_name"
              value-key="id"
              :items="clientList"
              :prompt-label="`Filter by ${Naming.Client}`">
              <action-button
                v-if="filters.client"
                slot="right"
                class="is-light"
                @click="clearFilter('client')">
                <icon icon="times"/>
              </action-button>
            </data-selector>
          </div>
          <div class="is-marginless align-self-center">
            <checkbox-input v-model="filters.only_upcoming" @input="runFilters" inner-label="Show only future dates"/>
          </div>
        </div>

      </div>

      <div class="box" v-if="loaded">
        <pager jump-controls :pageable="inspections" @nav="goToPage"/>
        <table class="table is-fullwidth is-vcentered is-hoverable">
          <thead>
          <tr>
            <th>{{ Naming.Asset }}/Type/Barcode</th>
            <th>{{ Naming.Site }}/{{ Naming.Zone }}</th>
            <th>Last {{ Naming.Inspection }}</th>
            <th>Next {{ Naming.Inspection }}</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="inspection in inspections.data" :key="inspection.id">
              <td>
                <p class="has-text-weight-bold">
                  <router-link
                    :to="{
                      name: 'asset-overview',
                      params: {
                        asset: inspection.asset_id
                      }
                    }">
                    {{ inspection.asset_code || inspection.asset_scanned_code }}
                  </router-link>
                </p>
                {{ inspection.asset_type_name }}, <span class="has-text-weight-bold">{{ inspection.asset_barcode && inspection.asset_barcode.data.length > 0 ? inspection.asset_barcode.data : inspection.asset_scanned_code || inspection.asset_code }}</span>
              </td>
              <td>
                <p class="has-text-weight-bold">{{ inspection.site_name }}</p>
                {{ inspection.zone_name }}
              </td>
              <td>
                <span class="inspection-checklist-name">
                  {{inspection.checklist_name}}
                </span><br>
                <span class="no-wrap">
                  {{inspection.last_inspection | date('L')}}
                </span>
              </td>
              <td>
                <span class="inspection-checklist-name">
                  {{inspection.checklist_name}}
                </span><br>
                <span 
                  v-if="inspection.next_inspection"  
                  :class="{
                    'has-text-danger': isPast(inspection.next_inspection),
                    'tooltip': isPast(inspection.next_inspection)}" 
                  :data-tooltip="isPast(inspection.next_inspection) ? `Overdue for ${Naming.Inspection.toLowerCase()}` : null">
                  <icon icon="clock" v-if="isPast(inspection.next_inspection)"/>
                  <span> {{ inspection.next_inspection | date('L') }}</span>
                </span>
                <span v-else>--</span>
              </td>
            </tr>
          </tbody>
        </table>

        <pager jump-controls :pageable="inspections" @nav="goToPage"/>
      </div>
      <loader v-else/>
    </template>
  </page>
</template>
<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import download from 'downloadjs'
import { inspection as backend } from '@/api'
import moment from "moment";

export default {

  data: () => ({

    loading: true,
    loaded: true,
    going_to_page: false,
    advanced: true,
    downloading: false,
    zoneList: [],
    modes: [
      { value: 'upcoming', label: 'Upcoming Inspections' },
      { value: 'last', label: 'Last Inspection' },
    ],
    filters: {
      search: '',
      job_number: '',
      status: null,
      checklist: null,
      date: '',
      site: '',
      page: '',
      asset_type: '',
      zone: '',
      client: '',
      mode: '',
      only_upcoming: null
    },

    statuses: [
      { label: 'Passed', value: 'passed' },
      { label: 'Failed', value: 'failed' },
      { label: 'Awaiting Sync', value: 'not_synced' },
      { label: 'In Progress', value: 'in_progress' },
    ],
  }),

  created() {
    this.filters = { ...this.$route.query }
    this.filters.only_upcoming = Boolean(false)
    Promise.all([
      this.$store.dispatch('checklist/loadChecklistSelectList'),
      this.$store.dispatch('site/loadSiteList'),
      this.$store.dispatch('assetType/loadAssetTypeList'),
      this.$store.dispatch('client/loadClientList'),
      this.loadInspections(),
    ]).then(() => {
      // this.filters.mode = 'upcoming'
      this.loading = false
    })
  },

  methods: {
    async loadInspections() {
      this.going_to_page = true
      await this.$store.dispatch('inspection/loadInspections', {
        path: 'api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
        this.loaded = true
        setTimeout(() => this.going_to_page = false, 100)
      })
    },
    goToPage(path) {
      this.going_to_page = true
      this.$router.push(path)
    },
    hasLocation(location) {
      return location !== null && Object.keys(location).length > 0
    },
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    addZones(siteId) {
      const site = this.siteList.find(item => item.id === siteId)
      if (site && site.zones) {
        this.zoneList = site.zones
      }
    },
    runFilters: debounce(function () {
      if (this.loading || this.going_to_page) return
      this.loaded = false
      this.$router.push({
        name: 'upcoming-inspection-index',
        query: this.filters
      })
    }, 500),

    filterStartDate(range) {
      this.filters.date = range
      if (!range) return
      this.$refs.startDate.picker.redraw()
      this.runFilters()
    },
    async pullXlsxReport() {
      this.downloading = true
      return await backend.downloadUpcomingInspectionsReportAsExcel(
        this.filters,
        response => this.downloadReport(response, 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
        () => {
          this.$whoops()
          this.downloading = false
        }
      )
    },
    downloadReport(response, fileExtension, responseContentType) {
      this.$toast.success(`Report retrieved. Please save the ${fileExtension.toUpperCase()}.`)
      download(
        response.data,
        `Upcoming ${this.Convert('Inspections')} as at ${moment().format('DD-MM-YYYY')}.${fileExtension}`,
        responseContentType
      )
      this.downloading = false
    },
    isPast(date) {
      return moment(date) < moment()
    }
  },

  computed: {
    ...mapGetters('inspection', [
      'inspections'
    ]),
    ...mapGetters('user', [
      'userList',
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ]),
    ...mapGetters('client', [
      'clientList'
    ])
  },

  watch: {
    '$route': 'loadInspections',
    'filters.site': function (value) {
      this.addZones(value)
    }
  }

}
</script>
