<template>
  <page :loading="loading" icon="list-alt" :title="`Latest Failed ${Naming.Checks}`">

    <template #tools>
      <div class="is-flex align-items-center">
        <router-link
            class="button is-primary has-text-weight-bold is-outlined is-rounded mr-1"
            :to="{
            name: 'inspection-index',
          }">
          <icon icon="angle-double-left"/>
          <span>{{ Naming.Inspections }}</span>
        </router-link>
        <action-button
            :disabled="!hasFailedChecks"
            :working="downloading"
            @click="downloadFailedChecksReport"
            class="is-primary has-text-weight-bold is-rounded">
          Export to CSV
        </action-button>
      </div>
    </template>

<!--    filters-->
    <div class="box is-flex mb-1">
      <div class="flex-grow grid gap-1 has-4-columns">

<!--asset code-->
        <text-input
            class="is-marginless"
            v-model="filters.code"
            @input="runFilters"
            left-icon="barcode"
            :placeholder="`${Naming.Asset} Internal ID`"
            :label="false">
          <action-button
              v-if="filters.code"
              slot="right"
              class="is-light"
              @click="clearFilter('code')">
            <icon icon="times"/>
          </action-button>
        </text-input>

<!--asset type-->
        <server-data-selector
            searchable
            class="is-marginless"
            left-icon="building"
            v-model="filters.asset_type_id"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.AssetType}`"
            :items="assetTypeList"
            @input="runFilters"
            :on-type="searchAssetTypes">
          <action-button
              v-if="filters.asset_type_id"
              slot="right"
              @click="clearFilter('asset_type_id')">
            <icon icon="times"/>
          </action-button>
        </server-data-selector>

<!--job ref-->
        <text-input
            class="is-marginless"
            v-model="filters.job_reference"
            @input="runFilters"
            left-icon="search"
            :placeholder="`${Naming.Job} Reference`"
            :label="false">
          <action-button
              v-if="filters.job_reference"
              slot="right"
              class="is-light"
              @click="clearFilter('job_reference')">
            <icon icon="times"/>
          </action-button>
        </text-input>

<!--job number-->
        <div class="is-flex">
          <input
              class="is-marginless input"
              type="number"
              v-model="filters.job_number"
              @input="runFilters"
              :placeholder="`#  ${Naming.Job} No.`">
          </input>
          <action-button
              v-if="filters.job_number"
              slot="right"
              class="is-light"
              @click="clearFilter('job_number')">
            <icon icon="times"/>
          </action-button>
        </div>

<!--date-->
        <date-picker
            class="is-marginless"
            placeholder="Filter by Date (Range)"
            mode="range"
            left-icon="calendar"
            :label="false"
            ref="startDate"
            :value="filters.failed_at"
            @datepicker-closed="runDateFilter"
            @input="filterStartDate">
          <action-button
              v-if="filters.failed_at"
              slot="right"
              @click="clearFilter('failed_at')">
            <icon icon="times"/>
          </action-button>
        </date-picker>

<!--checklist-->
        <server-data-selector
            searchable
            class="is-marginless"
            left-icon="check"
            v-model="filters.checklist_id"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Checklist}`"
            :items="checklistList"
            @input="runChecklistFilter"
            :on-type="searchChecklist"
            :on-lose-focus="clearChecklistFilter">
          <action-button
              v-if="filters.checklist_id"
              slot="right"
              @click="clearChecklistFilter">
            <icon icon="times"/>
          </action-button>
        </server-data-selector>

<!--check-->
        <data-selector
            v-if="filters.checklist_id"
            class="is-marginless"
            left-icon="check"
            v-model="filters.check_id"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Check}`"
            :items="checklistChecks"
            @input="runFilters">
          <action-button
              v-if="filters.check_id"
              slot="right"
              @click="clearFilter('check_id')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

<!--        site-->
        <server-data-selector
            searchable
            class="is-marginless"
            v-model="filters.site_id"
            @input="runFilters"
            left-icon="building"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Site}`"
            :items="siteList"
            :on-type="searchSite">
          <data-selector-site-slot
              slot="item"
              slot-scope="{ item }"
              :site="item"/>
          <action-button
              v-if="filters.site_id"
              slot="right"
              @click="clearFilter('site_id')">
            <icon icon="times"/>
          </action-button>
        </server-data-selector>

<!--        client-->
        <server-data-selector
            class="is-marginless"
            searchable
            v-model="filters.client_id"
            @input="runFilters"
            left-icon="address-book"
            label-key="legal_name"
            value-key="id"
            :items="clientList"
            :prompt-label="`Filter by ${Naming.Client}`"
            :on-type="searchClient">
          <action-button
              v-if="filters.client_id"
              slot="right"
              class="is-light"
              @click="clearFilter('client_id')">
            <icon icon="times"/>
          </action-button>
        </server-data-selector>
      </div>
    </div>

    <div class="box">
      <div class="is-flex justify-between mb-1">
        <pager jump-controls :pageable="failedChecks" @nav="goToPage"/>
        <p v-if="refreshedAt" class="refreshed-at-tag" >Data refreshed at {{ refreshedAt }}</p>
      </div>
      <table class="table is-fullwidth is-vcentered is-hoverable">
        <thead>
          <tr>
            <th>{{ Naming.Job }} Number / Reference</th>
            <th>{{ Naming.Inspection }} ID</th>
            <th>{{ Naming.Asset }} Internal ID / {{ Naming.AssetType }}</th>
            <th>{{ Naming.Checklist }}</th>
            <th>{{ Naming.Check }}</th>
            <th>Failed At</th>
            <th>{{ Naming.Site }} / {{ Naming.Client }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="check in failedChecks.data" :key="check.answer_id">
            <td>
              <router-link
                  :to="{
                      name: 'job-handler',
                      params: {
                        job: check.job_id
                      }
                    }"
              >
                <span class="has-text-weight-bold has-text-primary">#{{ check.job_number }}</span>
              </router-link>
              <p>{{ check.job_reference ?? '-' }}</p>
            </td>
            <td>
              <p class="has-text-weight-semibold has-text-grey-lighter" >
                <route name="inspection-evidence"
                       :params="{ asset: check.asset_id, inspection: check.inspection_id }">
                  {{ check.inspection_id }}
                </route>
              </p>
            </td>
            <td>
              <p class="has-text-weight-semibold" >{{ check.code }}</p>
              <p>{{ check.asset_type_name }}</p>
            </td>
            <td>
              {{ check.checklist_name }}
            </td>
            <td>
              {{ check.check_name }}
            </td>
            <td>
              {{ check.failed_at | date('L LTS') }}
            </td>
            <td>
              <p class="has-text-weight-bold">{{ check.site_name }}</p>
              <p>{{ check.client_name }}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!hasFailedChecks" class="has-text-grey has-text-centered mb-2">
        No Failed {{ Naming.Checks }}
      </p>
      <pager jump-controls :pageable="failedChecks" @nav="goToPage"/>
    </div>
  </page>
</template>
<script>


import {mapGetters} from "vuex";
import {debounce, forEach} from "lodash";
import Search from "@/utils/search";
import {inspection as backend} from "@/api";
import download from 'downloadjs'
import moment from "moment";

export default {
  name: 'failed-checks-index',

  created() {
    this.$store.dispatch('inspection/loadFailedChecks', {
      path: 'api' + this.$route.fullPath
    }).then(() => {
      this.loading = false
    })
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch('checklist/loadChecklistList'),
      this.$store.dispatch('assetType/loadAssetTypeList'),
      this.$store.dispatch('site/loadSiteList'),
      this.$store.dispatch('client/loadClientList'),
    ]).then(() => {
      this.setFilters()
    })
  },

  data: () => ({
    loading: true,
    downloading: false,
    goingToPage: false,
    filteredChecks: [],
    filters: {
      code: null,
      check_id: null,
      checklist_id: null,
      job_reference: null,
      job_number: null,
      asset_type_id: null,
      site_id: null,
      client_id: null,
      failed_at: null
    }
  }),

  computed: {
    ...mapGetters('inspection', ['failedChecks']),
    ...mapGetters('checklist', ['checklistList']),
    ...mapGetters('check', ['checklistChecks']),
    ...mapGetters('assetType', ['assetTypeList']),
    ...mapGetters('site', ['siteList']),
    ...mapGetters('client', ['clientList']),
    refreshedAt(){
      const date =  this.hasFailedChecks ? this.failedChecks.data[0].refresh_date : null
      return date ? moment(date).format('Y-MM-DD HH:MM:SS') : null
    },
    hasFailedChecks(){
      return this.failedChecks.data?.length > 0
    }
  },

  methods: {
    goToPage(path) {
      this.goingToPage = true
      this.$router.push(path)
    },
    downloadFailedChecksReport() {
      this.downloading = true
      this.$toast.success(this.Convert(`Downloading report`))
      backend.downloadFailedChecks(this.filters, ({data}) => {
        this.downloading = false
        download(data, `Latest-Failed-${this.Convert('Checks')}-${moment().format('YYYY-MM-DD')}.csv`, 'text/csv')
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    },
    runFilters: debounce(function () {
      if (this.loading || this.going_to_page) return
      this.$router.push({
        name: 'failed-checks-index',
        query: this.filters
      })
    }, 500),
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    searchChecklist(text) {
      Search.debouncedSearchByUri(text, 'checklist/searchChecklistSelectList', this.$store)
    },
    searchAssetTypes(text) {
      Search.debouncedSearchByUri(text, 'assetType/searchAssetTypes', this.$store)
    },
    searchClient(text) {
      Search.debouncedSearchByUri(text, 'client/searchClient', this.$store)
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    clearChecklistFilter() {
      this.clearFilter('check_id')
      this.clearFilter('checklist_id')
    },
    runChecklistFilter(){
      if(this.filters.checklist_id){
        this.$store.dispatch('check/getChecksFromChecklist', this.filters.checklist_id)
        this.runFilters()
      }
    },
    filterStartDate(range) {
      this.filters.failed_at = range
      if (!range) return
      this.$refs.startDate.picker.redraw()
    },
    runDateFilter(){
      if(this.filters.failed_at){
        this.runFilters()
      }
    },
    setFilters(){
      Object.keys(this.$route.query).forEach(key => this.filters[key] = this.$route.query[key])
    }
  },
  watch: {
    '$route': function(){
      this.$store.dispatch('inspection/loadFailedChecks', {
        path: 'api' + this.$route.fullPath
      })
    },
  },
}

</script>