<template>
<div>
  <component v-if="role" :is="'dashboard-admin'"/>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('auth', [
      'role'
    ])
  }

}
</script>
