<template>
<widget title="Recent Activity">
  <table class="table is-fullwidth">
    <tbody>
      <tr v-for="(inspections, group) in groupedInspections" :key="group">
        <td class="is-paddingless">
          <p class="mb">{{ group }}</p>
          <router-link :to="{
              name: 'job-handler',
              params: {
                job: inspection.job_id
              }
            }" class="is-flex mb-1" v-for="inspection in inspections" :key="inspection.id">
            <avatar
              :size="28"
              class="mr-1"
              :path="inspection.technician.profile.picture_url"
              :name="inspection.technician.full_name"/>
            <div>
              <p class="is-size-7 has-text-weight-medium">{{ inspection.technician.full_name }}</p>
              <p class="has-text-grey is-size-7">active <moment :time="inspection.created_at"/></p>
            </div>
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</widget>
</template>
<script>
import { groupBy } from 'lodash'

export default {
  
  props: {
    inspections: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    groupedInspections() {
      return groupBy(this.inspections, 'job.site.name')
    }
  }

}
</script>