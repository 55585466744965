<template>
  <page icon="download" title="Downloading...">
    <div class="pt-3 is-fullwidth is-flex is-flex-column align-items-center">
      <div class="is-size-1"><icon icon="download"></icon></div>
      <h1 class="is-size-1 text-xl has-text-weight-bold">Downloading</h1>
      <p class="is-flex is-size-4">Please wait for your download to start</p>
    </div>
  </page>
</template>

<script>
import download from 'downloadjs'
import { common } from '@/api'

export default {

  created() {
    common.downloadFile(
      `/api/download/${this.$route.params.filename}`,
      response => {
        download(
          response.data,
          `${this.$route.params.filename}`,
          'application/zip',
        )
        setTimeout(() => {this.goToPage('/')}, 5000)
      },
    )
  },

  methods: {
    goToPage(path) {
      this.$router.push(path)
      this.$scrollToTop()
    },
  },
}
</script>
